import { useState } from "react";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { Card, CardHeader, CardBody, Typography, Chip, Button, IconButton, Input } from "@material-tailwind/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { ProductDto } from "src/models/dtos";
import { dateFormat } from "src/utils/dateFormat";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "src/constants";
import { queryType } from "src/utils/queryType";
import { Pagination } from "src/components/Pagination";

interface ProductResponse {
	items: ProductDto[];
	total_pages: number;
	is_first_page: boolean;
	is_last_page: boolean;
	count: number;
}
interface ProductsProps {
	productFamilyGuid?: string;
	pageSize?: number;
}

export function Products(props: ProductsProps) {
	const navigate = useNavigate();
	const [queryString, setQueryString] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [searchType, setSearchType] = useState("search_name");

	const [activePage, setActivePage] = useState(1);
	const [pageSize, setPageSize] = useState(props.pageSize || 10);

	const fetchProducts = async (): Promise<ProductResponse> => {
		const response = await fetch(
			`${apiUrl}/internal_api/product/?page=${activePage}&page_size=${pageSize}${
				queryString ? "&" + searchType + "=" + queryString : ""
			}${props.productFamilyGuid ? "&product_family_guid=" + props.productFamilyGuid : ""}`,
			{
				credentials: "include",
			}
		);

		if (!response.ok) {
			throw new Error("Failed fetching products");
		}

		const productResponse = await response.json();

		return productResponse;
	};

	const { isPending, isSuccess, data } = useQuery({
		queryKey: ["products", activePage, pageSize, queryString, props.productFamilyGuid],
		queryFn: fetchProducts,
		placeholderData: keepPreviousData,
		retry: false,
		staleTime: 1000 * 60 * 5,
	});

	const handleClick = (productGuid: string) => {
		navigate(`/product/${productGuid}`);
	};

	const handleKeyDown = (e: any) => {
		if (e.key === "Enter") {
			search();
		}
	};

	const handlePageChange = (page: number) => {
		setActivePage(page);
	};

	const search = () => {
		setSearchType(queryType(searchValue));
		setQueryString(searchValue);
	};

	return (
		<div className="mt-12 mb-8 flex flex-col gap-12">
			<Card>
				<CardHeader variant="gradient" className="mb-8 p-6 bg-wise-500 flex flex-row items-center h-20">
					<Typography variant="h6" color="white">
						Products
					</Typography>
					<div className="ml-auto">
						<Input
							id="search"
							variant="standard"
							color="white"
							label="Search"
							crossOrigin={""}
							icon={
								<MagnifyingGlassIcon onClick={search} className="h-4 w-4 text-white cursor-pointer" />
							}
							onInput={(e) => setSearchValue(e.currentTarget.value)}
							onKeyDown={handleKeyDown}
						/>
					</div>
				</CardHeader>
				<CardBody className="overflow-x-auto px-0 pt-0 pb-2">
					<table className="w-full min-w-[640px] table-auto">
						<thead>
							<tr>
								{[
									"Name",
									"Icelandic Name",
									"Description",
									"Status",
									"Created Date",
									"Updated Date",
								].map((el) => (
									<th key={el} className="border-b border-blue-gray-50 py-3 px-5 text-left">
										<Typography
											variant="small"
											className="text-[11px] font-bold uppercase text-blue-gray-400"
										>
											{el}
										</Typography>
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{data &&
								data.items.map(
									(
										{
											productGuid,
											name,
											icelandic_name,
											description,
											status,
											createdAt,
											updatedAt,
										},
										key
									) => {
										const className = `py-3 px-5 ${
											key === data.items.length - 1 ? "" : "border-b border-blue-gray-50"
										}`;

										return (
											<tr
												key={productGuid}
												onClick={() => handleClick(productGuid)}
												className="hover:bg-gray-100 hover:cursor-pointer"
											>
												<td className={className}>
													<div className="flex items-center gap-4">
														<Typography
															variant="small"
															color="blue-gray"
															className="font-semibold"
														>
															{name}
														</Typography>
													</div>
												</td>
												<td className={className}>
													<Typography className="text-xs font-normal text-blue-gray-500">
														{icelandic_name ?? ""}
													</Typography>
												</td>
												<td className={className}>
													<Typography className="text-xs font-normal text-blue-gray-500 whitespace-nowrap text-ellipsis overflow-hidden w-60 max-w-80 lg:whitespace-normal lg:overflow-auto lg:w-full ">
														{description}
													</Typography>
												</td>
												<td className={className}>
													<Chip
														variant="gradient"
														color={status === "Active" ? "green" : "blue-gray"}
														value={status}
														className="py-0.5 px-2 text-[11px] font-medium w-fit"
													/>
												</td>
												<td className={className}>
													<Typography className="text-xs font-semibold text-blue-gray-600">
														{dateFormat(createdAt, true)}
													</Typography>
												</td>
												<td className={className}>
													<Typography className="text-xs font-semibold text-blue-gray-600">
														{dateFormat(updatedAt, true)}
													</Typography>
												</td>
											</tr>
										);
									}
								)}
						</tbody>
					</table>
				</CardBody>
			</Card>
			{data && data.total_pages > 1 && (
				<Pagination activePage={activePage} pagination={data} onPageChange={handlePageChange} />
			)}
		</div>
	);
}

export default Products;
