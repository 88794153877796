import { useState, useEffect } from "react";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { Card, CardHeader, CardBody, Typography, Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { ProductFamilyDto } from "src/models/dtos";
import { dateFormat } from "src/utils/dateFormat";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "src/constants";
import { Pagination } from "src/components/Pagination";

interface ProductFamilyResponse {
	items: ProductFamilyDto[];
	total_pages: number;
	is_first_page: boolean;
	is_last_page: boolean;
	count: number;
}

export function ProductFamilies() {
	const navigate = useNavigate();
	const [activePage, setActivePage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const fetchData = async (): Promise<ProductFamilyResponse> => {
		const response = await fetch(`${apiUrl}/internal_api/product/family?page=${activePage}&page_size=${pageSize}`, {
			credentials: "include",
		});

		if (!response.ok) {
			throw new Error("Failed fetching product families");
		}

		const productFamilyResponse = await response.json();

		return productFamilyResponse;
	};

	const { isPending, isSuccess, data } = useQuery({
		queryKey: ["productFamilies", activePage, pageSize],
		queryFn: fetchData,
		placeholderData: keepPreviousData,
		retry: false,
		staleTime: 1000 * 60 * 5,
	});

	const handleClick = (productGuid: string) => {
		navigate(`/productfamily/${productGuid}`);
	};

	const handlePageChange = (page: number) => {
		setActivePage(page);
	};

	return (
		<div className="mt-12 mb-8 flex flex-col gap-12">
			<Card>
				<CardHeader variant="gradient" className="mb-8 p-6 bg-wise-500 h-20 flex items-center">
					<Typography variant="h6" color="white">
						Product Families
					</Typography>
				</CardHeader>
				<CardBody className="overflow-x-auto px-0 pt-0 pb-2">
					<table className="w-full min-w-[640px] table-auto">
						<thead>
							<tr>
								{["Name", "Description", "Created Date", "Updated Date"].map((el) => (
									<th key={el} className="border-b border-blue-gray-50 py-3 px-5 text-left">
										<Typography
											variant="small"
											className="text-[11px] font-bold uppercase text-blue-gray-400"
										>
											{el}
										</Typography>
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{data &&
								data.items.map(
									({ productFamilyGuid, name, description, createdAt, updatedAt }, key) => {
										const className = `py-3 px-5 ${
											key === data.items.length - 1 ? "" : "border-b border-blue-gray-50"
										}`;

										return (
											<tr
												key={productFamilyGuid}
												onClick={() => handleClick(productFamilyGuid)}
												className="hover:bg-gray-100 hover:cursor-pointer"
											>
												<td className={className}>
													<div className="flex items-center gap-4">
														<div>
															<Typography
																variant="small"
																color="blue-gray"
																className="font-semibold"
															>
																{name}
															</Typography>
														</div>
													</div>
												</td>
												<td className={className}>
													<Typography className="text-xs font-normal text-blue-gray-500">
														{description ?? ""}
													</Typography>
												</td>
												<td className={className}>
													<Typography className="text-xs font-semibold text-blue-gray-600">
														{dateFormat(createdAt, true)}
													</Typography>
												</td>
												<td className={className}>
													<Typography className="text-xs font-semibold text-blue-gray-600">
														{dateFormat(updatedAt, true)}
													</Typography>
												</td>
											</tr>
										);
									}
								)}
						</tbody>
					</table>
				</CardBody>
			</Card>
			{data && data.total_pages > 1 && (
				<Pagination activePage={activePage} pagination={data} onPageChange={handlePageChange} />
			)}
		</div>
	);
}

export default ProductFamilies;
