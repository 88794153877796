import { Routes, Route } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useApp } from "src/context/AppContext";
import routes from "src/routes";
import { Sidenav, DashboardNavbar, Footer } from "src/widgets/layout";

export const AuthRouter = () => {
	const { isAuthenticated, getUserInfo } = useApp();

	const { isSuccess } = useQuery({
		queryKey: ["user"],
		queryFn: getUserInfo,
		retry: false,
		enabled: !!isAuthenticated,
	});

	return isSuccess ? (
		<>
			<div className="h-dvh bg-blue-gray-50/50">
				<Sidenav routes={routes} />
				<div className="flex flex-col p-4 xl:ml-80 min-h-screen">
					<DashboardNavbar />
					<Routes>
						<Route path="/" element={routes.home.element} />
						{routes.pages.map((route, i) => (
							<Route key={i} path={route.path} element={route.element} />
						))}
						{routes.detail.map((route, i) => (
							<Route key={i} path={route.path} element={route.element} />
						))}
					</Routes>
					<div className="mt-auto text-blue-gray-600 ">
						<Footer />
					</div>
				</div>
			</div>
		</>
	) : null;
};

export default AuthRouter;
