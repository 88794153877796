import { useState } from "react";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { Card, CardHeader, CardBody, Typography, Chip, Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { ProductVersionDto } from "src/models/dtos";
import { dateFormat } from "src/utils/dateFormat";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "src/constants";
import { Pagination } from "src/components/Pagination";

interface ProductVersionResponse {
	items: ProductVersionDto[];
	total_pages: number;
	is_first_page: boolean;
	is_last_page: boolean;
	count: number;
}

export function ProductVersions(props: any) {
	const navigate = useNavigate();
	const [activePage, setActivePage] = useState(1);
	const [pageSize, setPageSize] = useState(5);

	const fetchData = async (): Promise<ProductVersionResponse> => {
		const response = await fetch(
			`${apiUrl}/internal_api/product/${props.productGuid}/version?page=${activePage}&page_size=${pageSize}`,
			{
				credentials: "include",
			}
		);

		if (!response.ok) {
			throw new Error("Failed fetching product versions");
		}

		const productVersionResponse = await response.json();

		return productVersionResponse;
	};

	const { isPending, isSuccess, data } = useQuery({
		queryKey: ["productVersions", activePage, pageSize, props.productGuid],
		queryFn: fetchData,
		placeholderData: keepPreviousData,
		retry: false,
		staleTime: 1000 * 60 * 5,
	});

	const handleClick = (productVersionGuid: string) => {
		navigate(`/productversion/${productVersionGuid}`);
	};

	const handlePageChange = (page: number) => {
		setActivePage(page);
	};

	return (
		<div className="mt-12 mb-8 flex flex-col gap-12">
			<Card>
				<CardHeader variant="gradient" className="mb-8 p-6 bg-wise-500 h-20 flex items-center">
					<Typography variant="h6" color="white">
						Product Versions
					</Typography>
				</CardHeader>
				<CardBody className="overflow-x-auto px-0 pt-0 pb-2">
					<table className="w-full min-w-[640px] table-auto">
						<thead>
							<tr>
								{[
									"Name",
									"Description",
									"Version",
									"Runtime Version",
									"Status"
								].map((el) => (
									<th key={el} className="border-b border-blue-gray-50 py-3 px-5 text-left">
										<Typography
											variant="small"
											className="text-[11px] font-bold uppercase text-blue-gray-400"
										>
											{el}
										</Typography>
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{data &&
								data.items.map(
									(
										{
											productVersionGuid,
											name,
											description,
											major,
											minor,
											runtimeVersion,
											status
										},
										key
									) => {
										const className = `py-3 px-5 ${
											key === data.items.length - 1 ? "" : "border-b border-blue-gray-50"
										}`;

										return (
											<tr
												key={productVersionGuid}
												onClick={() => handleClick(productVersionGuid)}
												className="hover:bg-gray-100 hover:cursor-pointer"
											>
												<td className={className}>
													<div className="flex items-center gap-4">
														<div>
															<Typography
																variant="small"
																color="blue-gray"
																className="font-semibold"
															>
																{name}
															</Typography>
														</div>
													</div>
												</td>
												<td className={className}>
													<Typography className="text-xs font-normal text-blue-gray-500">
														{description ?? ""}
													</Typography>
												</td>
												<td className={className}>
													<Typography className="text-xs font-normal text-blue-gray-500">
														{major + "." + minor}
													</Typography>
												</td>
												<td className={className}>
													<Typography className="text-xs font-normal text-blue-gray-500">
														{runtimeVersion}
													</Typography>
												</td>
												<td className={className}>
													<Chip
														variant="gradient"
														color={status === "Active" ? "green" : "blue-gray"}
														value={status}
														className="py-0.5 px-2 text-[11px] font-medium w-fit"
													/>
												</td>
											</tr>
										);
									}
								)}
						</tbody>
					</table>
				</CardBody>
			</Card>
			{data && data.total_pages > 1 && (
				<Pagination activePage={activePage} pagination={data} onPageChange={handlePageChange} />
			)}
		</div>
	);
}

export default ProductVersions;
