import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { ProductFamilyDto } from "src/models/dtos";
import { Detail } from "src/pages/detail/detail";
import { apiUrl } from "src/constants";
import { Products } from "src/pages/dashboard";

export function ProductFamilyDetail() {
	const { productFamilyGuid } = useParams<{ productFamilyGuid: string }>();

	const fetchData = async (): Promise<ProductFamilyDto> => {
		const response = await fetch(`${apiUrl}/internal_api/product/family/${productFamilyGuid}`, {
			credentials: "include",
		});

		if (!response.ok) {
			throw new Error("Failed fetching product family");
		}

		const productFamilyResponse = await response.json();
		return productFamilyResponse;
	};

	const { data: productFamily } = useQuery({
		queryKey: ["productFamily", productFamilyGuid],
		queryFn: fetchData,
		placeholderData: keepPreviousData,
		retry: false,
	});

	return (
		<>
			{productFamily && <Detail object={productFamily} />}
			{productFamilyGuid && <Products productFamilyGuid={productFamilyGuid} />}
		</>
	);
}

export default ProductFamilyDetail;
