export const dateFormat = (inputDate: Date | undefined, showFullTime?: boolean) => {
    if (!inputDate) {
        return null;
    }

    const date = new Date(inputDate);

    const days = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const months = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const years = date.getFullYear();

    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    
    return `${days}.${months}.${years} 
        ${showFullTime ? `${hours}:${minutes}:${seconds}` : ''}`;
};