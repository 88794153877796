import { FC } from "react";
import PropTypes, { Validator } from "prop-types";
import { Typography } from "@material-tailwind/react";

interface Route {
	name: string;
	path: string;
}

interface FooterProps {
	brandName?: string;
	brandLink?: string;
	routes?: Route[];
}

export const Footer: FC<FooterProps> = ({
	brandName = "Wise",
	brandLink = "https://www.wise.is",
	routes = [
		{ name: "Wise", path: "https://wise.is/en/" },
		{ name: "About Us", path: "https://wise.is/en/wise/about-us/" },
	],
}) => {
	const year = new Date().getFullYear();

	return (
		<footer className="py-2">
			<div className="flex w-full flex-wrap items-center justify-center gap-6 px-2 md:justify-between">
				<Typography variant="small" className="font-normal text-inherit">
					&copy; {year}
				</Typography>
				<ul className="flex items-center gap-4">
					{routes.map(({ name, path }) => (
						<li key={name}>
							<Typography
								as="a"
								href={path}
								target="_blank"
								variant="small"
								className="py-0.5 px-1 font-normal text-inherit transition-colors hover:text-blue-500"
							>
								{name}
							</Typography>
						</li>
					))}
				</ul>
			</div>
		</footer>
	);
};

Footer.propTypes = {
	brandName: PropTypes.string,
	brandLink: PropTypes.string,
	routes: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			path: PropTypes.string.isRequired,
		})
	) as Validator<Route[]>,
};

Footer.displayName = "/src/widgets/layout/footer.jsx";

export default Footer;
