import React, { createContext, useContext, useReducer, useMemo } from "react";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { UserInfoResponse } from "src/types/User";
import { apiUrl } from "src/constants";

interface State {
	openSidenav: boolean;
	isAuthenticated: boolean;
	isLoading: boolean;
	csrfToken: string;
	user: UserInfoResponse;

	signOutMutation: UseMutationResult<Response, Error, void, unknown>;
	getUserInfo: () => Promise<Response>;
	getSession: () => Promise<Response>;
	getCsrfToken: () => Promise<Response>;
	dispatch: React.Dispatch<any>;
}

const initialState: State = {
	openSidenav: false,
	isAuthenticated: false,
	isLoading: false,
	csrfToken: "",
	user: {
		id: "",
		name: "",
		email: "",
		role: "",
	} as UserInfoResponse,

	signOutMutation: {} as UseMutationResult<Response, Error, void, unknown>,
	getUserInfo: async () => new Response(),
	getSession: async () => new Response(),
	getCsrfToken: async () => new Response(),
	dispatch: () => {},
};

const reducer = (state: State, action: any) => {
	switch (action.type) {
		case "OPEN_SIDENAV":
			return {
				...state,
				openSidenav: action.payload,
			};
		case "SET_USER":
			return {
				...state,
				user: action.payload,
			};
		case "SET_CSRF_TOKEN":
			return {
				...state,
				csrfToken: action.payload,
			};
		case "SET_LOADING":
			return {
				...state,
				isLoading: action.payload,
			};
		case "SET_IS_AUTHENTICATED":
			return {
				...state,
				isAuthenticated: action.payload,
			};
		case "SIGN_IN":
			return {
				...state,
				isAuthenticated: true,
				user: action.payload,
			};
		case "SIGN_OUT":
			return {
				...state,
				user: {
					id: "",
					name: "",
					email: "",
					role: "",
				} as UserInfoResponse,
				csrfToken: "",
				isAuthenticated: false,
			};
		default:
			return state;
	}
};

const AppContext = createContext(initialState);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const value = useMemo(() => {
		return { ...state, dispatch };
	}, [state, dispatch]);

	const signOut = async () => {
		const response = await fetch(`${apiUrl}/internal_api/auth/logout`, {
			method: "POST",
			credentials: "include",
			headers: {
				"X-CSRFToken": state.csrfToken,
				"Content-Type": "application/json",
			},
		});

		dispatch({ type: "SIGN_OUT" });

		return response;
	};

	const signOutMutation = useMutation({
		mutationFn: signOut,
	});

	const getUserInfo = async () => {
		const response = await fetch(`${apiUrl}/internal_api/auth/userinfo`, {
			method: "GET",
			credentials: "include",
		});

		if (!response.ok) {
			throw new Error("Error getting user info");
		}

		const userInfo = await response.json();

		dispatch({
			type: "SET_USER",
			payload: userInfo,
		});

		return response;
	};

	const getSession = async () => {
		const response = await fetch(`${apiUrl}/internal_api/auth/session`, {
			credentials: "include",
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (response.ok) {
			const data = await response.json();
			dispatch({ type: "SET_IS_AUTHENTICATED", payload: data.isAuthenticated });
		}

		return response;
	};

	const getCsrfToken = async () => {
		const response = await fetch(`${apiUrl}/internal_api/auth/csrf`, {
			credentials: "include",
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (response.ok) {
			const csrfToken = response.headers.get("X-CSRFToken");
			dispatch({ type: "SET_CSRF_TOKEN", payload: csrfToken });
		}

		return response;
	};

	return (
		<AppContext.Provider value={{ ...value, signOutMutation, getUserInfo, getSession, getCsrfToken, dispatch }}>
			{children}
		</AppContext.Provider>
	);
};

export const useApp = () => {
	const appContext = useContext(AppContext);

	if (!appContext) {
		throw new Error("useApp must be used within a AppContextProvider");
	}

	return appContext;
};

export default AuthProvider;
