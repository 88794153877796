export const ButtonTheme = {
    iconButton: {
        defaultProps: {
            color: "wise",
        },
        valid: {
            colors: [
                "wise",
                "wise-alt"
            ],
        },
        styles: {
            variants: {
                filled: {
                    wise: {
                        background: "bg-wise-500",
                        color: "text-white",
                        shadow: "shadow-md shadow-wise-500/20",
                        hover: "hover:shadow-lg hover:shadow-wise-500/40",
                        focus: "focus:opacity-[0.85] focus:shadow-none",
                        active: "active:opacity-[0.85] active:shadow-none",
                    },
                    "wise-alt": {
                        background: "bg-wise-alt-500",
                        color: "text-white",
                        shadow: "shadow-md shadow-wise-alt-500/20",
                        hover: "hover:shadow-lg hover:shadow-wise-alt-500/40",
                        focus: "focus:opacity-[0.85] focus:shadow-none",
                        active: "active:opacity-[0.85] active:shadow-none",
                    },
                },
                gradient: {
                    wise: {
                        background: "bg-gradient-to-tr from-wise-600 to-wise-400",
                        color: "text-white",
                        shadow: "shadow-md shadow-wise-500/20",
                        hover: "hover:shadow-lg hover:shadow-wise-500/40",
                        active: "active:opacity-[0.85]",
                    },
                    "wise-alt": {
                        background: "bg-gradient-to-tr from-wise-alt-600 to-wise-alt-400",
                        color: "text-white",
                        shadow: "shadow-md shadow-wise-alt-500/20",
                        hover: "hover:shadow-lg hover:shadow-wise-alt-500/40",
                        active: "active:opacity-[0.85]",
                    },
                },
                outlined: {
                    wise: {
                        border: "border border-wise-500",
                        color: "text-wise-500",
                        hover: "hover:opacity-75",
                        focus: "focus:ring focus:ring-wise-200",
                        active: "active:opacity-[0.85]",
                    },
                    "wise-alt": {
                        border: "border border-wise-alt-500",
                        color: "text-wise-alt-500",
                        hover: "hover:opacity-75",
                        focus: "focus:ring focus:ring-wise-alt-200",
                        active: "active:opacity-[0.85]",
                    },
                },
                text: {
                    wise: {
                        color: "text-wise-500",
                        hover: "hover:bg-wise-500/10",
                        active: "active:bg-wise-500/30",
                    },
                    "wise-alt": {
                        color: "text-wise-alt-500",
                        hover: "hover:bg-wise-alt-500/10",
                        active: "active:bg-wise-alt-500/30",
                    },
                },
            },
        },
    },
    button: {
        defaultProps: {
            color: "wise",
        },
        valid: {
            colors: [
                "wise",
                "wise-alt"
            ],
        },
        styles: {
            variants: {
                filled: {
                    wise: {
                        background: "bg-wise-500",
                        color: "text-white",
                        shadow: "shadow-md shadow-wise-500/20",
                        hover: "hover:shadow-lg hover:shadow-wise-500/40",
                        focus: "focus:opacity-[0.85] focus:shadow-none",
                        active: "active:opacity-[0.85] active:shadow-none",
                    },
                    "wise-alt": {
                        background: "bg-wise-alt-500",
                        color: "text-white",
                        shadow: "shadow-md shadow-wise-alt-500/20",
                        hover: "hover:shadow-lg hover:shadow-wise-alt-500/40",
                        focus: "focus:opacity-[0.85] focus:shadow-none",
                        active: "active:opacity-[0.85] active:shadow-none",
                    },
                },
                gradient: {
                    wise: {
                        background: "bg-gradient-to-tr from-wise-600 to-wise-400",
                        color: "text-white",
                        shadow: "shadow-md shadow-wise-500/20",
                        hover: "hover:shadow-lg hover:shadow-wise-500/40",
                        active: "active:opacity-[0.85]",
                    },
                    "wise-alt": {
                        background: "bg-gradient-to-tr from-wise-alt-600 to-wise-alt-400",
                        color: "text-white",
                        shadow: "shadow-md shadow-wise-alt-500/20",
                        hover: "hover:shadow-lg hover:shadow-wise-alt-500/40",
                        active: "active:opacity-[0.85]",
                    },
                },
                outlined: {
                    wise: {
                        border: "border border-wise-500",
                        color: "text-wise-500",
                        hover: "hover:opacity-75",
                        focus: "focus:ring focus:ring-wise-200",
                        active: "active:opacity-[0.85]",
                    },
                    "wise-alt": {
                        border: "border border-wise-alt-500",
                        color: "text-wise-alt-500",
                        hover: "hover:opacity-75",
                        focus: "focus:ring focus:ring-wise-alt-200",
                        active: "active:opacity-[0.85]",
                    },
                },
                text: {
                    wise: {
                        color: "text-wise-500",
                        hover: "hover:bg-wise-500/10",
                        active: "active:bg-wise-500/30",
                    },
                    "wise-alt": {
                        color: "text-wise-alt-500",
                        hover: "hover:bg-wise-alt-500/10",
                        active: "active:bg-wise-alt-500/30",
                    },
                },
            },
        },
    },
};

export default ButtonTheme;