import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { ProductVersionDto } from "src/models/dtos";
import { Detail } from "src/pages/detail/detail";
import { apiUrl } from "src/constants";
import { Artifacts } from "src/pages/dashboard";

export function ProductVersionDetail() {
    const { productVersionGuid } = useParams<{ productVersionGuid: string }>();

    const fetchData = async (): Promise<ProductVersionDto> => {
        const response = await fetch(`${apiUrl}/internal_api/product/version/${productVersionGuid}`, {
            credentials: "include",
        });

        if (!response.ok) {
            throw new Error("Failed fetching product version");
        }

        const productVersionResponse = await response.json();
        return productVersionResponse;
    };

    const { data: productVersion } = useQuery({
        queryKey: ["productVersion", productVersionGuid],
        queryFn: fetchData,
        placeholderData: keepPreviousData,
        retry: false,
    });

    return (
        <>
            {productVersion && <Detail object={productVersion} />}
            <div className="flex flex-col gap-4">
                {productVersionGuid && <Artifacts productVersionGuid={productVersionGuid} />}
            </div>
        </>
    );
}

export default ProductVersionDetail;
