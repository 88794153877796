import { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Typography, Input, Tab, Tabs, TabsHeader } from "@material-tailwind/react";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

import { ArtifactDto } from "src/models/dtos";
import { dateFormat } from "src/utils/dateFormat";
import { apiUrl } from "src/constants";
import { queryType } from "src/utils/queryType";
import Pagination from "src/components/Pagination";

interface ArtifactResponse {
    items: ArtifactDto[];
    total_pages: number;
    is_first_page: boolean;
    is_last_page: boolean;
    count: number;
}
interface UserInfoResponse {
    first_name: string;
    last_name: string;
    email: string;
    is_staff: boolean;
    is_superuser: boolean;
    user_permissions: string[];
}

interface ArtifactsProps {
    productVersionGuid?: string;
    pageSize?: number;
}

export function Artifacts(props: ArtifactsProps) {
    const navigate = useNavigate();
    const [queryString, setQueryString] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [searchType, setSearchType] = useState("search_name");

    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(props.pageSize || 10);
    const [isRuntime, setIsRuntime] = useState(false);
    const [isBuildPackage, setIsBuildPackage] = useState(false);

    const fetchArtifacts = async (): Promise<ArtifactResponse> => {
        const queryParams = new URLSearchParams({
            page: activePage.toString(),
            page_size: pageSize.toString(),
            is_build_package: isBuildPackage.toString(),
        });

        if (isBuildPackage) {
            queryParams.set("is_build_package", "true");
        } else {
            queryParams.set("is_runtime", isRuntime.toString());
            queryParams.set("is_build_package", "false");
        }

        if (props.productVersionGuid) {
            queryParams.append("product_version_guid", props.productVersionGuid);
        }
        if (queryString) queryParams.append(searchType, queryString);
        const response = await fetch(`${apiUrl}/internal_api/artifact/?${queryParams.toString()}`, {
            credentials: "include",
        });

        if (!response.ok) {
            throw new Error("Failed fetching artifacts");
        }

        const artifactResponse = await response.json();
        return artifactResponse;
    };

    const fetchUserData = async (): Promise<UserInfoResponse> => {
        const response = await fetch(`${apiUrl}/internal_api/auth/userinfo`, {
            method: "GET",
            credentials: "include",
        });

        if (!response.ok) {
            throw new Error("Error getting user info");
        }

        return await response.json();
    };

    const {
        data: userInfo,
        isPending: isUserInfoPending,
        isSuccess: isUserInfoSuccess,
    } = useQuery({
        queryKey: ["userInfo"],
        queryFn: fetchUserData,
    });

    const { isPending, isSuccess, data } = useQuery({
        queryKey: ["artifacts", activePage, pageSize, queryString, props.productVersionGuid, isRuntime, isBuildPackage],
        queryFn: fetchArtifacts,
        placeholderData: keepPreviousData,
        retry: false,
        staleTime: 1000 * 60 * 5,
    });

    const handleClick = (artifactGuid: string) => {
        navigate(`/artifact/${artifactGuid}`);
    };

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            search();
        }
    };

    const handlePageChange = (page: number) => {
        setActivePage(page);
    };

    const search = () => {
        setSearchType(queryType(searchValue));
        setQueryString(searchValue);
    };
    const tabsData = [
        {
            label: "Released Packages",
            value: "Released",
        },
        {
            label: "Runtime Packages",
            value: "Runtime",
        },
        ...(userInfo && userInfo.is_staff
            ? [
                  {
                      label: "Build Packages",
                      value: "BuildPackage",
                  },
              ]
            : []),
    ];

    return (
        <div className="mt-12 mb-8 flex flex-col gap-12">
            <Card>
                <CardHeader variant="gradient" className="mb-8 p-6 bg-wise-500 flex flex-row items-center h-20">
                    <Tabs value={!isRuntime ? "Released" : "Runtime"}>
                        <TabsHeader>
                            {tabsData.map(({ label, value }) => (
                                <Tab
                                    key={value}
                                    value={value}
                                    className="flex-grow text-center min-w-[200px]"
                                    onClick={() => {
                                        setIsRuntime(value === "Runtime");
                                        setIsBuildPackage(value === "BuildPackage");
                                    }}
                                >
                                    {label}
                                </Tab>
                            ))}
                        </TabsHeader>
                    </Tabs>

                    <div className="ml-auto flex">
                        <Input
                            variant="standard"
                            color="white"
                            label="Search"
                            crossOrigin={""}
                            icon={
                                <MagnifyingGlassIcon onClick={search} className="h-4 w-4 text-white cursor-pointer" />
                            }
                            onInput={(e) => setSearchValue(e.currentTarget.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </CardHeader>
                <CardBody className="overflow-x-auto px-0 pt-0 pb-2">
                    <table className="w-full min-w-[640px] table-auto">
                        <thead>
                            <tr>
                                <th className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                    <Typography
                                        variant="small"
                                        className="text-[11px] font-bold uppercase text-blue-gray-400"
                                    >
                                        Name
                                    </Typography>
                                </th>
                                {isRuntime && (
                                    <>
                                        <th className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                            <Typography
                                                variant="small"
                                                className="text-[11px] font-bold uppercase text-blue-gray-400"
                                            >
                                                Cumulative Release
                                            </Typography>
                                        </th>
                                        <th className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                            <Typography
                                                variant="small"
                                                className="text-[11px] font-bold uppercase text-blue-gray-400"
                                            >
                                                Is Runtime
                                            </Typography>
                                        </th>
                                    </>
                                )}
                                <th className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                    <Typography
                                        variant="small"
                                        className="text-[11px] font-bold uppercase text-blue-gray-400"
                                    >
                                        Created Date
                                    </Typography>
                                </th>
                                <th className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                    <Typography
                                        variant="small"
                                        className="text-[11px] font-bold uppercase text-blue-gray-400"
                                    >
                                        Updated Date
                                    </Typography>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data &&
                                data.items.map(
                                    (
                                        { artifactGuid, name, cumulativeRelease, isRuntime, createdAt, updatedAt },
                                        key
                                    ) => {
                                        const className = `py-3 px-5 ${
                                            key === data.items.length - 1 ? "" : "border-b border-blue-gray-50"
                                        }`;
                                        return (
                                            <tr
                                                key={artifactGuid}
                                                onClick={() => handleClick(artifactGuid)}
                                                className="hover:bg-gray-100 hover:cursor-pointer"
                                            >
                                                <td className={className}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-semibold"
                                                    >
                                                        {name}
                                                    </Typography>
                                                </td>
                                                {isRuntime && (
                                                    <>
                                                        <td className={className}>
                                                            <Typography className="text-xs font-normal text-blue-gray-500">
                                                                {cumulativeRelease ?? ""}
                                                            </Typography>
                                                        </td>
                                                        <td className={className}>
                                                            <Typography className="text-xs font-normal text-blue-gray-500">
                                                                {isRuntime ? "Yes" : "No"}
                                                            </Typography>
                                                        </td>
                                                    </>
                                                )}
                                                <td className={className}>
                                                    <Typography className="text-xs font-semibold text-blue-gray-600">
                                                        {dateFormat(createdAt, true)}
                                                    </Typography>
                                                </td>
                                                <td className={className}>
                                                    <Typography className="text-xs font-semibold text-blue-gray-600">
                                                        {dateFormat(updatedAt, true)}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            {data && data.total_pages > 1 && (
                <Pagination activePage={activePage} pagination={data} onPageChange={handlePageChange} />
            )}
        </div>
    );
}

export default Artifacts;
