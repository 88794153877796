import { useLocation } from "react-router-dom";
import { Navbar, Typography, Button, IconButton, Breadcrumbs } from "@material-tailwind/react";
import { UserCircleIcon, Bars3Icon } from "@heroicons/react/24/solid";
import { useApp } from "src/context/AppContext";

export function DashboardNavbar() {
	const { openSidenav, dispatch } = useApp();
	const { pathname } = useLocation();
	const { signOutMutation } = useApp();

	const page = pathname.split("/").filter((el) => el !== "");

	return (
		<Navbar color="transparent" className="rounded-xl transition-allpx-0 py-1" fullWidth blurred={false}>
			<div className="flex justify-end gap-6 md:flex-row md:items-center">
				<div className="flex items-center">
					<IconButton
						variant="text"
						color="blue-gray"
						className="grid xl:hidden"
						onClick={() => dispatch({ type: "OPEN_SIDENAV", payload: !openSidenav })}
					>
						<Bars3Icon strokeWidth={3} className="h-6 w-6 text-blue-gray-500" />
					</IconButton>

					<Button
						variant="text"
						color="blue-gray"
						className="flex items-center gap-1 px-4 normal-case"
						onClick={() => signOutMutation.mutate()}
					>
						<UserCircleIcon className="h-5 w-5 text-blue-gray-500" />
						Sign out
					</Button>
				</div>
			</div>
		</Navbar>
	);
}

DashboardNavbar.displayName = "/src/widgets/layout/dashboard-navbar.jsx";

export default DashboardNavbar;
