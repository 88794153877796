export const queryType = (inputType: string) => {
	if (!inputType) {
		return "";
	}

	const uuidFormat = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

	if (uuidFormat.test(inputType)) {
		return "search_guid";
	}

	return "search_name";
};
