import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { ArtifactDto } from "src/models/dtos";
import { Detail } from "src/pages/detail/detail";
import { apiUrl } from "src/constants";

export function ArtifactDetail() {
	const { artifactGuid } = useParams<{ artifactGuid: string }>();

	const fetchData = async (): Promise<ArtifactDto> => {
		const response = await fetch(`${apiUrl}/internal_api/artifact/${artifactGuid}`, {
			credentials: "include",
		});

		if (!response.ok) {
			throw new Error("Failed fetching artifact");
		}

		const artifactResponse = await response.json();
		return artifactResponse;
	};

	const { data: artifact } = useQuery({
		queryKey: ["artifact", artifactGuid],
		queryFn: fetchData,
		placeholderData: keepPreviousData,
		retry: false,
	});

	return <>{artifact && <Detail object={artifact} />}</>;
}

export default ArtifactDetail;
