import { Card, CardBody, Typography, IconButton } from "@material-tailwind/react";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";

import { ObjectDetailCard } from "src/widgets/cards";
import { apiUrl } from "src/constants";

export function Detail(props: any) {
	const handleDownload = async (e: any, blobUrl: string) => {
		try {
			const response = await fetch(`${apiUrl}${blobUrl}`, {
				credentials: "include",
			});

			if (!response.ok) {
				const responseJson = await response.json();
				console.log("error");
				throw new Error(responseJson);
			}

			const contentType = response.headers.get("Content-Type");

			if (contentType !== "application/octet-stream") {
				throw new Error("Content-Type is not application/octet-stream");
			}
			const contentDisposition = response.headers.get("Content-Disposition");
			const fileName = contentDisposition
				?.split(";")
				.map((item) => item.trimStart())
				.find((item) => item.startsWith("filename="))
				?.split("=")[1]
				.replace(/"/g, "");

			if (!fileName) {
				throw new Error("Content-Disposition does not contain filename");
			}

			const blob = await response.blob();

			const url = window.URL.createObjectURL(new Blob([blob], { type: contentType }));

			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
			window.URL.revokeObjectURL(url);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<div className="relative mt-8 h-36 w-full rounded-xl bg-wise-500 bg-cover bg-center">
				<div className="absolute inset-0 h-full w-full" />
			</div>
			<Card className="mx-3 -mt-24 mb-6 lg:mx-4 border border-blue-gray-100">
				<CardBody className="p-6">
					<div className="mb-10 flex items-center justify-between flex-wrap gap-6">
						<div className="flex items-center gap-6">
							<div>
								<Typography variant="h5" color="blue-gray" className="mb-1">
									{props.object.name}
								</Typography>
								{props.object.version && (
									<Typography variant="small" className="font-normal text-blue-gray-600">
										Version: {props.object.version}
									</Typography>
								)}
							</div>
						</div>
						{props.object.downloadLink && (
							<IconButton
								variant="gradient"
								onClick={(e) => handleDownload(e, props.object.downloadLink)}
							>
								<ArrowDownTrayIcon strokeWidth={3} className="h-6 w-6 text-white" />
							</IconButton>
						)}
					</div>
					<div className="grid-cols-1 mb-12 grid gap-12 px-4">
						<ObjectDetailCard
							title="Description"
							description={props.object.description}
							details={props.object}
						/>
					</div>
				</CardBody>
			</Card>
		</>
	);
}

export default Detail;
