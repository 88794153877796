import { Routes, Route } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Spinner, Typography } from "@material-tailwind/react";

import AuthRouter from "src/router/AuthRouter";
import { useApp } from "src/context/AppContext";
import { useEffect } from "react";
import { apiUrl } from "src/constants";

const Root = () => {
	const { isAuthenticated, getSession, csrfToken, getCsrfToken, isLoading } = useApp();

	const { isLoading: sessionLoading } = useQuery({
		queryKey: ["session"],
		queryFn: getSession,
		retry: false,
		enabled: !isAuthenticated,
	});

	const { isLoading: csrfLoading } = useQuery({
		queryKey: ["csrfToken"],
		queryFn: getCsrfToken,
		retry: false,
		enabled: !csrfToken,
	});

	useEffect(() => {
		if (!isAuthenticated && !isLoading && !sessionLoading && !csrfLoading) {
			window.location.href = `${apiUrl}/internal_api/auth/microsoft/login`;
		}
	}, [isAuthenticated, isLoading, sessionLoading, csrfLoading]);

	return (
		<>
			{false ? (
				<div className="flex flex-col justify-center items-center h-screen gap-5">
					<Spinner color="gray" className="h-36 w-36 text-gray-500/50" />
					<Typography variant="h3" color="blue-gray" className="text-center">
						Loading...
					</Typography>
				</div>
			) : (
				<Routes>
					<Route path="/*" element={<AuthRouter />} />
				</Routes>
			)}
		</>
	);
};

export default Root;
