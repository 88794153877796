import { CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import CustomCard from "src/components/CustomCard";
import { useQuery } from "@tanstack/react-query";
import { apiUrl } from "src/constants";

export const Home = () => {
	const { data, isLoading } = useQuery({
		queryKey: ["dashboard"],
		queryFn: async () => {
			const response = await fetch(`${apiUrl}/internal_api/portal/dashboard`, {
				credentials: "include",
			});

			if (response.ok) {
				return response.json();
			}
		},
		retry: false,
	});

	return (
		<div className="grid grid-cols-1 auto-cols-fr gap-5 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4 mt-6 2xl:px-12">
			<CustomCard color="primary" variant="gradient" className="h-64 p-8">
				<CardHeader
					floated={false}
					shadow={false}
					color="transparent"
					className="m-0 rounded-none border-b border-white/10 pb-4 text-center"
				>
					<Typography variant="h6">Product Families</Typography>
				</CardHeader>
				{isLoading ? (
					<Spinner className="m-auto h-16 w-16 text-gray-900/50" />
				) : (
					<CardBody className="flex flex-col p-0 m-auto">
						<Typography className="text-6xl text-center break-all">{data?.productFamilies}</Typography>
					</CardBody>
				)}
			</CustomCard>
			<CustomCard color="primary" variant="gradient" className="h-64 p-8">
				<CardHeader
					floated={false}
					shadow={false}
					color="transparent"
					className="m-0 rounded-none border-b border-white/10 pb-4 text-center"
				>
					<Typography variant="h6">Products</Typography>
				</CardHeader>
				{isLoading ? (
					<Spinner className="m-auto h-16 w-16 text-gray-900/50" />
				) : (
					<CardBody className="flex flex-col p-0 m-auto">
						<Typography className="text-6xl text-center break-all">{data?.products}</Typography>
					</CardBody>
				)}
			</CustomCard>
			<CustomCard color="primary" variant="gradient" className="h-64 p-8">
				<CardHeader
					floated={false}
					shadow={false}
					color="transparent"
					className="m-0 rounded-none border-b border-white/10 pb-4 text-center"
				>
					<Typography variant="h6">Artifacts</Typography>
				</CardHeader>
				{isLoading ? (
					<Spinner className="m-auto h-16 w-16 text-gray-900/50" />
				) : (
					<CardBody className="flex flex-col p-0 m-auto">
						<Typography className="text-6xl text-center break-all">{data?.artifacts}</Typography>
					</CardBody>
				)}
			</CustomCard>
			<CustomCard color="primary" variant="gradient" className="h-64 p-8">
				<CardHeader
					floated={false}
					shadow={false}
					color="transparent"
					className="m-0 rounded-none border-b border-white/10 pb-4 text-center"
				>
					<Typography variant="h6">Product Versions</Typography>
				</CardHeader>
				{isLoading ? (
					<Spinner className="m-auto h-16 w-16 text-gray-900/50" />
				) : (
					<CardBody className="flex flex-col p-0 m-auto">
						<Typography className="text-6xl text-center break-all">{data?.productVersions}</Typography>
					</CardBody>
				)}
			</CustomCard>
		</div>
	);
};

export default Home;
