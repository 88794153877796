import { Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

type Pagination = {
	total_pages: number;
	is_first_page: boolean;
	is_last_page: boolean;
	count: number;
};

interface PaginationProps {
	activePage: number;
	pagination: Pagination;
	onPageChange: (page: number) => void;
}

export const Pagination = (props: PaginationProps) => {
	const { activePage, pagination, onPageChange } = props;

	const getItemProps = (index: number) =>
		({
			variant: activePage === index ? "filled" : "text",
			ripple: false,
			className: "transition duration-0 delay-0",
			onClick: () => onPageChange(index),
		} as any);

	const next = () => {
		if (pagination.is_last_page) return;

		onPageChange(activePage + 1);
	};

	const prev = () => {
		if (props.pagination.is_first_page) return;

		onPageChange(activePage - 1);
	};

	const getPagination = () => {
		const pages = [];
		let startIndex = Math.max(activePage - 3, 0);

		if (!pagination) return;

		if (activePage > pagination.total_pages - 3) {
			startIndex = Math.max(pagination.total_pages - 5, 0);
		}

		let endIndex = Math.min(startIndex + 5, pagination.total_pages);

		for (let i = startIndex; i < endIndex; i++) {
			pages.push(
				<IconButton key={i} {...getItemProps(i + 1)}>
					{i + 1}
				</IconButton>
			);
		}

		return pages;
	};

	return (
		<div className="flex justify-center items-center gap-4">
			<Button
				variant="text"
				className="flex items-center gap-2"
				onClick={prev}
				disabled={pagination.is_first_page}
			>
				<ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Previous
			</Button>
			<div className="flex items-center gap-2">{getPagination()}</div>
			<Button
				variant="text"
				className="flex items-center gap-2"
				onClick={next}
				disabled={pagination.is_last_page}
			>
				Next
				<ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
			</Button>
		</div>
	);
};

export default Pagination;
