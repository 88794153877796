import PropTypes from "prop-types";
import { Card, CardHeader, CardBody, Typography } from "@material-tailwind/react";

interface ProfileInfoCardProps {
	title: string;
	description?: React.ReactNode;
	details?: Record<string, string | React.ReactNode>;
}

const keysToSkip = ["description", "productVersion"];

export function ObjectDetailCard({ title, description, details = {} }: ProfileInfoCardProps) {
	return (
		<Card color="transparent" shadow={false}>
			<CardHeader
				color="transparent"
				shadow={false}
				floated={false}
				className="mx-0 mt-0 mb-4 flex items-center justify-between gap-4"
			>
				<Typography variant="h6" color="blue-gray">
					{title}
				</Typography>
			</CardHeader>
			<CardBody className="p-0">
				{description && (
					<Typography variant="small" className="font-normal text-blue-gray-500">
						{description ?? ""}
					</Typography>
				)}
				{description && details ? <hr className="my-8 border-blue-gray-50" /> : null}
				{details && (
					<ul className="flex flex-col gap-4 p-0">
						{Object.keys(details)
							.filter((k) => !keysToSkip.includes(k))
							.map((el, key) => (
								<li key={key} className="flex items-center gap-4">
									<Typography variant="small" color="blue-gray" className="font-semibold capitalize">
										{el}:
									</Typography>
									{typeof details[el] === "string" ? (
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{details[el] ?? ""}
										</Typography>
									) : (
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{details[el]?.toString() ?? ""}
										</Typography>
									)}
								</li>
							))}
					</ul>
				)}
			</CardBody>
		</Card>
	);
}

ObjectDetailCard.defaultProps = {
	description: null,
	details: {},
};

ObjectDetailCard.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.node,
	details: PropTypes.object,
};

ObjectDetailCard.displayName = "/src/widgets/cards/profile-info-card.jsx";

export default ObjectDetailCard;
