import {
	HomeIcon,
	RectangleGroupIcon,
	ListBulletIcon,
	EllipsisHorizontalIcon,
	ArchiveBoxIcon,
} from "@heroicons/react/24/solid";
import { ProductFamilies, Products, Artifacts, Home } from "src/pages/dashboard";
import ArtifactDetail from "src/pages/detail/artifact-detail";
import ProductDetail from "src/pages/detail/product-detail";
import ProductFamilyDetail from "src/pages/detail/productfamily-detail";
import ProductVersionDetail from "src/pages/detail/productversion-detail";

const icon = {
	className: "w-5 h-5 text-inherit",
};

export const routes = {
	home: {
		icon: <HomeIcon {...icon} />,
		name: "",
		path: "/",
		element: <Home />,
	},
	pages: [
		{
			icon: <ListBulletIcon {...icon} />,
			name: "Products",
			path: "/products",
			element: <Products />,
		},
		{
			icon: <RectangleGroupIcon {...icon} />,
			name: "Product Families",
			path: "/productfamilies",
			element: <ProductFamilies />,
		},
		{
			icon: <ArchiveBoxIcon {...icon} />,
			name: "Artifacts",
			path: "/artifacts",
			element: <Artifacts />,
		},
	],
	detail: [
		{
			icon: <EllipsisHorizontalIcon {...icon} />,
			name: "Artifact Detail",
			path: "/artifact/:artifactGuid",
			element: <ArtifactDetail />,
		},
		{
			icon: <EllipsisHorizontalIcon {...icon} />,
			name: "Product Version Detail",
			path: "/productversion/:productVersionGuid",
			element: <ProductVersionDetail />,
		},
		{
			icon: <EllipsisHorizontalIcon {...icon} />,
			name: "Product Detail",
			path: "/product/:productGuid",
			element: <ProductDetail />,
		},
		{
			icon: <EllipsisHorizontalIcon {...icon} />,
			name: "Product Family Detail",
			path: "/productfamily/:productFamilyGuid",
			element: <ProductFamilyDetail />,
		},
	],
};

export default routes;
