import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { ProductDto } from "src/models/dtos";
import { Detail } from "src/pages/detail/detail";
import { apiUrl } from "src/constants";
import { ProductVersions } from "src/pages/detail/subTables/productversions";

export function ProductDetail() {
	const { productGuid } = useParams<{ productGuid: string }>();

	const fetchData = async (): Promise<ProductDto> => {
		const response = await fetch(`${apiUrl}/internal_api/product/${productGuid}`, {
			credentials: "include",
		});

		if (!response.ok) {
			throw new Error("Failed fetching product");
		}

		const productResponse = await response.json();
		return productResponse;
	};

	const { data: product } = useQuery({
		queryKey: ["product", productGuid],
		queryFn: fetchData,
		placeholderData: keepPreviousData,
		retry: false,
	});

	return (
		<>
			{product && <Detail object={product} />}
			{productGuid && <ProductVersions productGuid={productGuid} />}
		</>
	);
}

export default ProductDetail;
