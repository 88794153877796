import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { IconButton, Typography, Button } from "@material-tailwind/react";
import { useApp } from "src/context/AppContext";
import { TouchEvent } from "react";
import { ReactComponent as WiseLogo } from "src/images/logo.svg";

interface Route {
	icon: JSX.Element;
	name: string;
	path: string;
	element: JSX.Element;
}
interface Routes {
	home: Route;
	pages: Route[];
}

interface SidenavProps {
	brandName?: string;
	routes: Routes;
}

export function Sidenav({ routes, brandName = "Wise Internal Management" }: SidenavProps) {
	const { openSidenav, dispatch } = useApp();

	const [touchStart, setTouchStart] = useState(0);
	const [touchEnd, setTouchEnd] = useState(0);

	// the required distance between touchStart and touchEnd to be detected as a swipe
	const minSwipeDistance = 50;

	const onTouchStart = (e: TouchEvent<HTMLElement>) => {
		setTouchEnd(0); // otherwise the swipe is fired even with usual touch events
		setTouchStart(e.targetTouches[0].clientX);
	};

	const onTouchMove = (e: TouchEvent<HTMLElement>) => setTouchEnd(e.targetTouches[0].clientX);

	const onTouchEnd = () => {
		if (!touchStart || !touchEnd) return;
		const distance = touchStart - touchEnd;
		const isLeftSwipe = distance > minSwipeDistance;
		const isRightSwipe = distance < -minSwipeDistance;

		if (isLeftSwipe) {
			dispatch({ type: "OPEN_SIDENAV", payload: false });
		}
	};

	return (
		<aside
			className={`${
				openSidenav ? "translate-x-0" : "-translate-x-80"
			} bg-white shadow-sm fixed inset-0 z-50 my-4 ml-4 h-[calc(100vh-32px)] w-72 rounded-xl transition-transform duration-300 xl:translate-x-0 border border-blue-gray-100`}
			onTouchStart={onTouchStart}
			onTouchMove={onTouchMove}
			onTouchEnd={onTouchEnd}
		>
			<div className="flex justify-between items-center px-6 pt-6 xl:hidden">
				<Link to={routes.home.path} className="w-6 h-6">
					<WiseLogo />
				</Link>
				<IconButton
					variant="text"
					color="blue-gray"
					size="sm"
					ripple={false}
					className="rounded-br-none rounded-tl-none"
					onClick={() => dispatch({ type: "OPEN_SIDENAV", payload: false })}
				>
					<XMarkIcon strokeWidth={2.5} className="h-5 w-5" />
				</IconButton>
			</div>
			<div className="hidden xl:flex justify-center items-center p-6">
				<Link to={routes.home.path} className="text-center">
					<Typography variant="h6" color={"blue-gray"}>
						{brandName}
					</Typography>
				</Link>
			</div>
			<div className="m-4">
				<ul className="mb-4 flex flex-col gap-1">
					{routes.pages.map((route, i) => (
						<li key={i}>
							<NavLink to={route.path}>
								{({ isActive }) => (
									<Button
										color={!isActive ? "blue-gray" : undefined}
										variant={isActive ? "gradient" : "text"}
										className="flex items-center gap-4 px-4 capitalize"
										fullWidth
									>
										{route.icon}
										<Typography color="inherit" className="font-medium capitalize">
											{route.name}
										</Typography>
									</Button>
								)}
							</NavLink>
						</li>
					))}
				</ul>
			</div>
		</aside>
	);
}

Sidenav.displayName = "/src/widgets/layout/sidnave.jsx";

export default Sidenav;
